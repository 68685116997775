import { Link } from 'gatsby';
import * as React from 'react';

import ContentPage from '../../components/ContentPage';
import LinksList from '../../components/LinksList';
import PageImage from '../../components/PageImage';

import image from '../../images/undraw_pair_programming.svg';

const ServicesPage = () => {

    const navLinks = [
        {label: 'Development Services', route:'development', children: [
            {label: 'Web Applications', route:'webapps'},
            {label: 'Mobile Apps', route:'mobileapps'},
            {label: 'Websites', route:'websites'},
        ]},
        {label: 'Quality Control', route:'quality', children: [
            {label: 'Analysis and Design', route:'design'},
            {label: 'Testing', route:'testing'},
            {label: 'Training', route:'training'},
        ]},
        {label: 'System Management and Support', route:'operations'},
    ]

    return <ContentPage>
        <h1>Services</h1>
        <PageImage image={image} alt='Services' />
        <p>Coralpeak Systems offers a wide range of services covering the whole life of a business application from its
            creation to deployment and daily operation.  Our technical skills will complement your business vision to 
            ensure that your application is built correctly, implemented efficiently, and runs smoothly in order to meet 
            your objectives and budget.</p>
        <p>Our standalone consultancy and support services can deliver enhanced results for your project and application,
            even if we are not developing it for you.</p>
        <LinksList caption='Find out more about our services:' links={navLinks} />
        <p>Please <Link to='/contact'>contact us</Link> to discuss your objectives and how we can help you.</p>

    </ContentPage>
}

export default ServicesPage